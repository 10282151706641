import { ReactSVG } from "react-svg";
import { MapShape, getMapShapeUrl } from "./shapes";
import { CSSProperties } from "react";

type Props = {
    className?: string;
    fillColor: any;
    onClick?: (event: any) => void;
    shape: MapShape;
    size: number;
    strokeColor: any;
    strokeWidth: string;
    style?: CSSProperties;
    wrapper?: "div" | "span" | "svg";
    x?: number;
    y?: number;
}

export default function MapShapeSVG({
    className,
    fillColor,
    onClick,
    shape,
    size,
    strokeColor,
    strokeWidth,
    style,
    wrapper,
    x,
    y,
}: Props): JSX.Element {
    if (!shape) {
        return null;
    }
    
    return <ReactSVG
        className={className}
        src={getMapShapeUrl(shape)}
        renumerateIRIElements={false}
        wrapper={wrapper}
        x={x}
        y={y}
        onClick={onClick}
        style={style}
        beforeInjection={
            (svg) => {
                svg.setAttribute('width', size.toString());
                svg.setAttribute('height', size.toString());

                const rects: SVGElement[] = Array.from(svg.getElementsByTagName('rect'));
                const paths: SVGElement[] = Array.from(svg.getElementsByTagName('path'));
                const circles: SVGElement[] = Array.from(svg.getElementsByTagName('circle'));

                const elements = rects.concat(paths, circles);

                for (const element of elements) {
                    element.style.fill = fillColor;
                    element.style.stroke = strokeColor;
                    element.style.strokeWidth = strokeWidth;
                }
            }
        }
    />;
}
