import { Intent, Spinner, SpinnerSize, Tag } from "@blueprintjs/core";
import { useActiveGamesProvider } from "../global/activegamesprovider";
import styles from './navigationregular.module.css';
import { useSession } from "next-auth/react";

export default function NewGameCount(): JSX.Element {
    const { activeGames: games } = useActiveGamesProvider();
    const { data: session, status } = useSession();
    const userId = status === 'authenticated' ? session.user.id : null;

    if (!games) {
        return <Spinner size={SpinnerSize.SMALL} style={{ display: 'inline-flex' }} />;
    }

    let numGames = 0;
    for (const game of games) {
        if (
            game.activeSeats.some(
                (seat) => seat.userId === userId,
            )
            || game.invites?.find(
                (invite) => invite.user?.id === userId,
            )
        ) {
            ++numGames;
        }
    }

    const className = numGames > 0
        ? styles.gameIndicatorOn
        : styles.gameIndicatorOff;

    return <Tag className={className} intent={Intent.SUCCESS} round={true}>
        {numGames}
    </Tag>
}
