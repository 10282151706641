import getItemById from "../../src/util/getItemById";
import defaultColors from "../../src/types/color";
import PlayerAvatar from "./avatar";
import styles from './playeravatar.module.css';
import { useDialogManager } from "../global/dialog";
import { useCallback, useMemo } from "react";

export enum UserPlayerAvatarSize {
    Small = 0,
    Medium,
}
import { BOT_AVATAR } from "../../src/models/game/bot";
import PlayerProfileDialog from "../game/play/dialogs/playerprofiledialog";
import { ApiGame, ApiSeat } from "../../src/types/apigame";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useSession } from "next-auth/react";

type Props = {
    game?: ApiGame;
    hasGameStarted?: boolean;
    seatObject?: ApiSeat;
    showProfileOnClick?: boolean;
    disableAcceptedDimming?: boolean;
    size?: UserPlayerAvatarSize;
};

export default function UserPlayerAvatar({
    game,
    hasGameStarted,
    seatObject,
    showProfileOnClick = true,
    disableAcceptedDimming = false,
    size,
}: Props): JSX.Element {
    const { data: session, status } = useSession();
    const { addDialog, onCloseDialog } = useDialogManager();

    const myUserId = status === 'authenticated' && session.user.id;

    const classes: string[] = [styles.colorBox];

    if (
        (hasGameStarted && !seatObject?.isAlive) ||
        (!seatObject?.accepted && !disableAcceptedDimming)
    ) {
        classes.push(styles.isNotAlive);
    }

    if (
        game?.activeSeats?.some(
            (activeSeatObject) => activeSeatObject.seatNumber === seatObject?.seatNumber,
        )
    ) {
        classes.push(styles.turn);
    }

    if (size === UserPlayerAvatarSize.Medium) {
        classes.push(styles.medium);
    } else {
        classes.push(styles.small);
    }

    const userId = seatObject?.userId;

    let onClick = useCallback(
        (event: React.MouseEvent) => {
            addDialog(
                <PlayerProfileDialog
                    seatObject={seatObject}
                    onClose={onCloseDialog}
                />,
            );

            event.stopPropagation();
        },
        [seatObject, addDialog, onCloseDialog],
    );

    const color = useMemo(
        () => {
            if (seatObject?.color != null) {
                return defaultColors.get(seatObject?.color)?.hex;
            }

            return undefined;
        },
        [seatObject],
    );

    let avatarSrc: string;
    if (userId) {
        avatarSrc = seatObject.user?.avatar;
    } else if (seatObject?.bot?.id) {
        avatarSrc = BOT_AVATAR;
    } else {
        avatarSrc = null;
        classes.push(styles.emptySeat);
    }

    const mySeatObject = useMemo(
        () => game?.seats.find(
            (seatObjectIterator) => seatObjectIterator.userId === myUserId,
        ),
        [game, myUserId],
    );

    const playerAvatar = useMemo(
        () => {
            if (!userId && !avatarSrc && !mySeatObject) {
                return <Icon
                    className={styles.avatar}
                    icon={IconNames.Plus}
                />;
            } else if (!userId && !avatarSrc && mySeatObject) {
                return null;
            }

            return <PlayerAvatar
                className={styles.avatar}
                onClick={showProfileOnClick ? onClick : null}
                src={avatarSrc}
                title={seatObject.name}
            />
        },
        [avatarSrc, mySeatObject, onClick, seatObject, showProfileOnClick, userId],
    );

    return (
        <div
            className={classes.join(' ')}
            style={{
                backgroundColor: color,
            }}
        >
            {playerAvatar}
        </div>
    );
}
