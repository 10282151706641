import { UnsignedByte } from "./byte";

export enum ColorId {
    Gray = -1,
    Red = 0,
    Lime = 1,
    Blue = 2,
    Yellow = 3,
    Cyan = 4,
    Pink = 5,
    Maroon = 8,
    Green = 10,
    Purple = 11,
    Teal = 12,
    Navy = 13,
    Orange = 14,
    Brown = 15,
}

export type CountryColor = {
    id: ColorId;
    name: string;
    hex: string;
    rgb: [UnsignedByte, UnsignedByte, UnsignedByte];
};

class ColorList extends Array<CountryColor> {
    public addColor(id: ColorId, name: string, red: UnsignedByte, green: UnsignedByte, blue: UnsignedByte) {
        const color: CountryColor ={
            id,
            name,
            hex: `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`,
            rgb: [red, green, blue],
        };
        this.push(color);
        this._map.set(id, color);
    }
    public get(id: ColorId) {
        return this._map.get(id);
    }

    private _map = new Map<ColorId, CountryColor>();
}

const defaultColors = new ColorList();
defaultColors.addColor(ColorId.Gray, 'Gray', 128, 128, 128);
defaultColors.addColor(ColorId.Red, 'Red', 255, 0, 0);
defaultColors.addColor(ColorId.Lime, 'Lime', 0, 255, 0);
defaultColors.addColor(ColorId.Blue, 'Blue', 0, 0, 255);
defaultColors.addColor(ColorId.Yellow, 'Yellow', 255, 255, 0);
defaultColors.addColor(ColorId.Cyan, 'Cyan', 0, 255, 255);
defaultColors.addColor(ColorId.Pink, 'Pink', 255, 0, 255);
defaultColors.addColor(ColorId.Maroon, 'Maroon', 128, 0, 0);
defaultColors.addColor(ColorId.Green, 'Green', 0, 128, 0);
defaultColors.addColor(ColorId.Purple, 'Purple', 128, 0, 128);
defaultColors.addColor(ColorId.Teal, 'Teal', 0, 128, 128);
defaultColors.addColor(ColorId.Navy, 'Navy', 0, 0, 128);
defaultColors.addColor(ColorId.Orange, 'Oragne', 255, 140, 0);
defaultColors.addColor(ColorId.Brown, 'Brown', 139, 69, 19);

export const COLOR_NEUTRAL = defaultColors.get(ColorId.Gray);

export function isValidPlayerColor(colorId: number): boolean {
    return colorId >= 0 && ColorId[colorId] != undefined;
}

export default defaultColors;
