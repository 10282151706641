import React from 'react';
import { SessionProvider } from "next-auth/react"
import dynamic from 'next/dynamic';

import '../styles/global.css';
import BuildInfo from '../components/global/build_info';
import { SocketProvider } from '../components/global/socket';
import Notify from '../components/global/notify';
import { FocusStyleManager } from '@blueprintjs/core';
import { PageFocusProvider } from '../components/global/pagefocus';
import { SoundManagerProvider } from '../components/global/sound/soundmanager';
import DialogManager from '../components/global/dialog';
import { ActiveGamesProvider } from '../components/global/activegamesprovider';
import NavigationRegular from '../components/navbar/navigationregular';
import SpqrHead from '../components/global/spqrhead';
import PresenceProvider from '../components/global/presence';
import { ServerFlagsProvider } from '../components/global/serverflags';
import BlueprintPopupHack from '../components/global/blueprintpopuphack';
import VerifyNotif from '../components/global/verifynotif';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next/types';
import { getServerSession } from 'next-auth';
import { authOptions } from './api/auth/[...nextauth]';
import { ApiGame } from '../src/types/apigame';
import { gameGetGamesForUser } from '../src/operations/game';
import { GetGamesForUserType } from '../src/models/socket/game';

const ThemeProvider = dynamic(
    () => import('../components/global/theme'),
    { ssr: false },
);

type Props = InferGetServerSidePropsType<typeof getServerSideProps>;

export const getServerSideProps = (async (context) => {
    const { req, res } = context;
    const session = await getServerSession(req, res, authOptions);

    if (!session) {
        return {
            props: {},
        };
    }

    const userId = session.user.id;

    const activeGames: ApiGame[] = await gameGetGamesForUser(
        userId,
        {
            searchType: GetGamesForUserType.Active,
        },
    );

    const openGames: ApiGame[] = await gameGetGamesForUser(
        userId,
        {
            searchType: GetGamesForUserType.Open,
        },
    );

    const games = activeGames.filter(
        (game) => game.activeSeats.find(
            (seat) => seat.userId === userId,
        )
            || game.invites.find(
                (invite) => invite.user?.id === userId,
            ),
    );

    return {
        props: {
            fromServer: {
                games,
                openGames,
            },
        }
    };
}) satisfies GetServerSideProps<{
        fromServer?: {
            games?: ApiGame[],
            openGames?: ApiGame[],
        }
}>


export default function App(
    {
        Component,
        pageProps: { session, fromServer, ...pageProps }
    },
) {
    FocusStyleManager.onlyShowFocusOnTabs();

    return (
        <SessionProvider session={session}>
            <ThemeProvider>
                <SocketProvider>
                    <ServerFlagsProvider>
                        <PageFocusProvider>
                            <ActiveGamesProvider fromServer={fromServer}>
                                <SoundManagerProvider>
                                    <PresenceProvider>
                                        <DialogManager>
                                            <Notify />
                                            <SpqrHead />
                                            <NavigationRegular />
                                            <VerifyNotif />
                                            <div id="main">
                                                <Component {...pageProps} />
                                            </div>
                                            <BuildInfo />
                                            <BlueprintPopupHack />
                                        </DialogManager>
                                    </PresenceProvider>
                                </SoundManagerProvider>
                            </ActiveGamesProvider>
                        </PageFocusProvider>
                    </ServerFlagsProvider>
                </SocketProvider>
            </ThemeProvider>
        </SessionProvider>
    );
}
