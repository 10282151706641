import { HTMLSelect } from "@blueprintjs/core";
import { useCallback } from "react";
import { useLocalStorage } from 'usehooks-ts';
import defaultColors from "../../../src/types/color";
import { LocalStorageKeys } from "../../../src/enum/localstorage";

export default function SettingsBackgroundColor(): JSX.Element {
    const [backgroundColor, setBackgroundColor] = useLocalStorage<number>(LocalStorageKeys.BackgroundColor, 0);

    const onChange = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            setBackgroundColor(parseInt(event.currentTarget.value, 10))
        },
        [setBackgroundColor],
    )

    const colors = {
        'Map Default': 0,
        'White': 1,
        'Black': 2,
        'Grey': 3,
    };
    for (const colorObj of defaultColors) {
        colors[colorObj.name] = colorObj.id + 4;
    }

    return (
        <div>
            <div><b>Background Color:</b></div>
            <div>
                <HTMLSelect
                    options={
                        Object.keys(colors)
                            .map((key) => ({
                                label: key,
                                value: String(colors[key])
                            }))
                    }
                    onChange={onChange}
                    value={backgroundColor}
                />
            </div>
        </div>
    );
}
