import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import Title from '../components/global/title';
import { Spinner } from '@blueprintjs/core';
import NotLoggedIn from '../components/home/notloggedin/notloggedin';
import GameGamesList from '../components/game/gameslist';
import HomeGamesHeaderCard from '../components/home/gamesheadercard';
import PullToRefresh from '../components/global/pulltorefresh';
import { useActiveGamesProvider } from '../components/global/activegamesprovider';
import Game from '../src/models/game';

export default function Homepage() {
    const { data: session, status } = useSession();
    const router = useRouter();
    const {activeGames: allActiveGames, openGames} = useActiveGamesProvider();

    const onRefresh = useCallback(
        () => {
            router.reload();
        },
        [router],
    );

    useEffect(
        () => {
            if (router.query.error) {
                console.error(router.query.error);
            }
        },
        [router],
    );

    if (status === 'loading') {
        return <Spinner />;
    } else if (status === 'unauthenticated') {
        return <NotLoggedIn />;
    }

    const userId = session.user.id;
    const activeGames = allActiveGames.filter(
        game => game.activeSeats.some(
            seat => seat.userId === userId
        )
    );


    return (
        <div id="content">
            <PullToRefresh onRefresh={onRefresh}>
                <Title />
                <HomeGamesHeaderCard games={activeGames} />
                <GameGamesList
                    games={activeGames}
                    userId={userId}
                />
                <h1>Open Games:</h1>
                <GameGamesList
                    games={openGames}
                    userId={userId}
                />
            </PullToRefresh>
        </div>
    );
}
