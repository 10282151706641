import { Socket } from "socket.io-client";
import { ApiGame } from "../types/apigame";
import { GetGamesForUserParams, GetGamesForUserType } from "../models/socket/game";
import { ClientRequests } from "../enum/socket";

class ActiveGamesClient {
    constructor(
        protected socket: Socket,
        protected userId: number,
    ) {
    }

    public async getActiveGames(): Promise<ApiGame[] | null> {
        return this.getGameList(GetGamesForUserType.Active);
    }

    public async getOpenGames(): Promise<ApiGame[] | null> {
        return this.getGameList(GetGamesForUserType.Open);
    }

    public async getGame(gameId: number): Promise<ApiGame | null> {
        if (!this.socket || !this.userId) {
            return null;
        }

        const params: GetGamesForUserParams = {
            gameIds: [gameId],
        };

        let getGamesResult: ApiGame[];

        try {
            getGamesResult = await this.socket.emitWithAck(
                ClientRequests.GetGames,
                params,
            );
        } catch (err) {
            return null;
        }

        if (getGamesResult?.length > 0) {
            return getGamesResult[0];
        } else {
            return null;
        }
    }

    private async getGameList(searchType: GetGamesForUserType): Promise<ApiGame[] | null> {
        if (!this.socket || !this.userId) {
            return null;
        }

        const params: GetGamesForUserParams = {
            searchType,
        };

        let getGamesResult: ApiGame[];

        try {
            getGamesResult = await this.socket.emitWithAck(
                ClientRequests.GetGames,
                params,
            );
        } catch (err) {
            return null;
        }

        return getGamesResult;
    }
}

export default ActiveGamesClient;
