import { useSession } from 'next-auth/react';
import GameState from '../../src/enum/gamestate';
import { GameSocketMessageEventTypes, GameSocketNotifyMessageTypes, IGameSocketNotifyMessage } from '../../src/models/game/socket';
import { ApiGame } from '../../src/types/apigame';
import { usePageFocus } from '../../components/global/pagefocus';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSocket } from './socket';
import ActiveGamesClient from '../../src/util/activegames';
import { GameStateChanges } from '../../src/types/socket';

export type ActiveGamesProviderValue = {
    activeGames: ApiGame[];
    openGames: ApiGame[];
}

const ActiveGamesProviderContext = createContext<ActiveGamesProviderValue>({
    activeGames: [],
    openGames: [],
});

type Props = {
    fromServer?: {
        activeGames?: ApiGame[];
        openGames?: ApiGame[];
    };
    children: ReactNode;
};

export function ActiveGamesProvider({ fromServer, children }: Props) {
    const isPageFocused = usePageFocus();
    const { socket } = useSocket();
    const [activeGames, setActiveGames] = useState<ApiGame[]>(fromServer?.activeGames ?? []);
    const [openGames, setOpenGames] = useState<ApiGame[]>(fromServer?.openGames ?? []);

    const { data: session, status } = useSession();
    const userId = status === 'authenticated' ? session.user.id : null;

    const client = useMemo(
        (): ActiveGamesClient => new ActiveGamesClient(socket, userId),
        [socket, userId],
    );

    const initGames = useCallback(
        async (): Promise<void> => {
            if (!isPageFocused) {
                return;
            }

            if (!fromServer?.activeGames?.length) {
                const newGames = await client.getActiveGames();
                if (newGames) {
                    setActiveGames(newGames);
                }
            }
            if (!fromServer?.openGames?.length) {
                const newGames = await client.getOpenGames();
                if (newGames) {
                    setOpenGames(newGames);
                }
            }
        },
        [client, fromServer, setActiveGames, setOpenGames, isPageFocused],
    );

    const update = useCallback(
        (newGame: ApiGame) => (oldGames: ApiGame[]) => {
            const newGames = [...oldGames];

            const index = newGames.findIndex(
                (game) => game.id === newGame.id,
            );

            if (index >= 0) {
                newGames[index] = newGame;
            } else {
                newGames.push(newGame);
            }

            return newGames.filter(
                (game) => game.state !== GameState.Complete,
            );
        },
        [],
    );

    const updateActiveGame = useCallback(
        async (gameId: number): Promise<void> => {
            const newGame = await client.getGame(gameId);
            if (!newGame) {
                return;
            }


            setActiveGames(update(newGame));
        },
        [client, setActiveGames, update],
    );

    const updateOpenGame = useCallback(
        async (gameId: number): Promise<void> => {
            const newGame = await client.getGame(gameId);
            if (!newGame) {
                return;
            }


            setOpenGames(update(newGame));
        },
        [client, setOpenGames, update],
    );

    useEffect(
        () => {
            if (!socket) {
                return;
            }

            initGames();

            socket.on('connect', initGames);
            return () => {
                socket.off('connect', initGames);
            }
        },
        [socket, initGames],
    );

    useEffect(
        () => {
            if (!socket) {
                return;
            }

            const onNotifyGameChanged = (data: GameStateChanges) => {
                updateActiveGame(data.gameId);
            };

            const onActiveGameChange = (message: IGameSocketNotifyMessage) => {
                switch (message.type) {
                    case GameSocketNotifyMessageTypes.Invited:
                    case GameSocketNotifyMessageTypes.Turn:
                    case GameSocketNotifyMessageTypes.DeclinedInvite:
                    case GameSocketNotifyMessageTypes.GameStarted:
                    case GameSocketNotifyMessageTypes.Joined:
                    case GameSocketNotifyMessageTypes.TurnEnded:
                    case GameSocketNotifyMessageTypes.NotifyGameChanged: {
                        const gameId = message.gameId;
                        updateActiveGame(gameId);

                        break;
                    }
                    case GameSocketNotifyMessageTypes.Open: {
                        const gameId = message.gameId;
                        updateOpenGame(gameId);

                        break;
                    }
                }
            };

            socket.on(GameSocketMessageEventTypes.Notify, onActiveGameChange);
            socket.on('notifyGameChanged', onNotifyGameChanged);

            return () => {
                socket.off(GameSocketMessageEventTypes.Notify, onActiveGameChange);
                socket.off('notifyGameChanged', onNotifyGameChanged);
            };
        },
        [socket, updateActiveGame, updateOpenGame],
    );

    const value = useMemo(
        (): ActiveGamesProviderValue => ({ activeGames, openGames }),
        [activeGames, openGames],
    );

    return (
        <ActiveGamesProviderContext.Provider value={value}>
            {children}
        </ActiveGamesProviderContext.Provider>
    );
}

export function useActiveGamesProvider() {
    return useContext(ActiveGamesProviderContext);
}
