import { PresenceStatus } from "../../server/socket/types";

export enum GameSocketMessageEventTypes {
    Notify = 'notify',
    Presence = 'presence',
};

export enum GameSocketNotifyMessageTypes {
    Turn = 'turn',
    TurnEnded = 'turnEnded',
    GameStarted = 'gameStarted',
    Invited = 'invited',
    Joined = 'joined',
    DeclinedInvite = 'declinedInvite',
    NotifyGameChanged = 'notifyGameChanged',
    Open = 'open',
};

export interface IGameSocketNotifyMessage {
    type: GameSocketNotifyMessageTypes;
    gameId: number;
};

export type GameSocketPresenceMessage = {
    userId: number;
    status: PresenceStatus;
}
