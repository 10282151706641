export enum SpqrUrls {
    EditProfile = '/user/editprofile',
    Game = '/game',
    Login = '/user/login',
    Map = '/map',
    Stats = '/stats',
}

export const URL_LOGIN = '/user/login';

export const ALLOWED_LOGIN_CALLBACK_URL_PAGE_RES = [
    /^\/$/,
    /^\/game$/,
    /^\/game\/play\?id=\d+$/,
    /^\/stats$/,
    /^\/map$/,
    /^\/map\/edit/,
];

export const isValidLoginCallbackUrl = (callbackUrl: string): boolean =>
    ALLOWED_LOGIN_CALLBACK_URL_PAGE_RES.some(url_re => callbackUrl.match(url_re));

export const urlLoginWithCallback = (callbackUrl: string): string => {
    if (!isValidLoginCallbackUrl(callbackUrl)) {
        callbackUrl = '/';
    }
    return SpqrUrls.Login + '?callbackUrl=' + callbackUrl;
}
