import { Card, Intent, OverlayToaster } from "@blueprintjs/core";
import { signIn } from "next-auth/react";
import { useState } from "react";
import HomepageAccountForm from "../../components/login/accountform";
import HomepageBottomText from "../../components/login/bottomtext";
import { homepageForgotPassword, homepageRegister } from "../../src/thunks/homepage";
import styles from './accountform.module.css';
import { validateUserRegistrationParams } from "../../src/models/user/auth";

export enum HomepageStatus {
    RegisterAccount = 0,
    Login,
}

interface HomepageAccountFormContainerArgs {
    status?: HomepageStatus;
    successPath?: string;
    error?: string;
};

export default function HomepageAccountFormContainer(
    {
        error,
        status,
        successPath,
    }: HomepageAccountFormContainerArgs,
) {
    const [formStatus, setFormStatus] = useState(status ?? HomepageStatus.Login);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [isSubmitDisabled, isSetSubmitDisabled] = useState(false);

    const onSubmit = async () => {
        isSetSubmitDisabled(true);

        const fn = (formStatus === HomepageStatus.Login) ? onLogin : onRegister;

        try {
            await fn(email, password, name);
        } catch (err) {
            console.error(`Error`, err);

            const toaster = await OverlayToaster.createAsync();
            toaster.show({
                intent: Intent.DANGER,
                message: `Error: ${err.message}`,
            });
        } finally {
            isSetSubmitDisabled(false);
        }
    }

    const onLogin = async (loginEmail, loginPassword) => {
        const response = await signIn(
            'credentials',
            {
                email: loginEmail,
                password: loginPassword,
                callbackUrl: `${successPath ?? window.location.origin}`,
            },
        );

        return response;
    }

    const onRegister = async (registerEmail, registerPassword, registerName) => {
        const errorMessage = validateUserRegistrationParams(
            registerEmail,
            registerPassword,
            registerName,
        );

        if (errorMessage) {
            throw new Error(errorMessage);
        }

        const response = await homepageRegister(registerEmail, registerPassword, registerName);

        if (response.status === "error") {
            throw new Error(response.message);
        } else {
            setFormStatus(HomepageStatus.Login);

            const toaster = await OverlayToaster.createAsync();
            toaster.show({
                intent: Intent.SUCCESS,
                message: 'Account created! Now log in.',
            });
        }
    }

    const forgotPassword = async () => {
        if (email.length === 0) {
            alert('enter your email address');
            return;
        }
        isSetSubmitDisabled(true);
        try {
            const response = await homepageForgotPassword(email);
            if (response.status !== 'ok') {
                console.error('bad response:', response);
            }
        } finally {
            isSetSubmitDisabled(false);
        }
    }

    const onToggleStatus = () => {
        setFormStatus(
            formStatus === HomepageStatus.Login
                ? HomepageStatus.RegisterAccount
                : HomepageStatus.Login,
        );
    };

    const buttonText = formStatus === HomepageStatus.Login
        ? "Log in"
        : "Register";

    return (
        <Card>
            <h1>{formStatus === HomepageStatus.Login ? 'Login' : 'Register'}</h1>

            {error && <h3 className={styles.error}>{error}</h3>}

            <HomepageAccountForm
                buttonText={buttonText}
                email={email}
                hideName={formStatus === HomepageStatus.Login}
                isSubmitDisabled={isSubmitDisabled}
                name={name}
                onSubmit={onSubmit}
                password={password}
                setEmail={setEmail}
                setName={setName}
                setPassword={setPassword}
                forgotPassword={forgotPassword}
            />

            <HomepageBottomText
                status={formStatus}
                onClick={onToggleStatus}
            />
        </Card>
    );
}
